/* src/components/Landing.css */

/* Basic styling for the Landing page */
.landing-container {
    font-family: Arial, sans-serif;
    background-color: #f7f9fc;
    padding: 2rem;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  /* Styling the options container for registered users */
  .options-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Styling the "Choose your type" message */
  .options-container p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #666;
  }
  
  /* Button container styling */
  .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .type-button {
    background-color: #4CAF50;
    color: white;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 1rem;
  }
  
  .type-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }
  
  /* Styling for small screens */
  @media (max-width: 768px) {
    .landing-container {
      padding: 1rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .options-container {
      padding: 1rem;
    }
  
    .button-container {
      flex-direction: column;
      gap: 0.8rem;
    }
  
    .type-button {
      width: 100%;
    }
  }
  
  /* Styling for very small screens */
  @media (max-width: 480px) {
    h1 {
      font-size: 1.8rem;
    }
  
    .options-container {
      padding: 0.8rem;
    }
  
    .type-button {
      padding: 0.8rem;
      font-size: 0.9rem;
    }
  }
  