/* src/components/UserSummary.css */

/* Container for the entire page */
.summary-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
  }
  
  .user-card {
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 1.5rem;
  }
  
  .user-info, .menu-items {
    flex: 1;
    padding: 1rem;
    margin: 1rem;
  }
  
  .user-info {
    max-width: 50%;
  }
  
  .menu-items {
    max-width: 50%;
    border-left: 2px solid #e0e0e0;
    padding-left: 2rem;
  }
  
  h2 {
    font-size: 1.8rem;
    color: #4CAF50;
  }
  
  h3 {
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  strong {
    color: #333;
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
  }
  
  .menu-item {
    background-color: #f1f1f1;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .menu-item p {
    margin: 0.5rem 0;
  }
  
  /* Loading, error, and no data states */
  .loading, .error, .no-data {
    text-align: center;
    font-size: 1.2rem;
    color: #888;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .summary-container {
      padding: 1rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .user-card {
      flex-direction: column;
      padding: 1rem;
    }
  
    .user-info, .menu-items {
      max-width: 100%;
      padding: 0.5rem;
    }
  
    .menu-items {
      border-left: none;
      padding-left: 0;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.6rem;
    }
  
    .user-card h2 {
      font-size: 1.5rem;
    }
  
    .menu-item p {
      font-size: 0.8rem;
    }
  }
  