.signup-container {
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .signup-box {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-form .ant-form-item {
    margin-bottom: 16px;
  }
  
  .top-right-link {
    text-align: right;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .create-account-button {
    background-color: #1677ff;
    color: white;
  }
  
  .google-signup-button {
    border: 1px solid #d9d9d9;
    color: #595959;
  }
  
  .password-strength {
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 16px;
  }
  
  .password-strength.weak {
    color: red;
  }
  
  .password-strength.medium {
    color: orange;
  }
  
  .password-strength.strong {
    color: green;
  }
  