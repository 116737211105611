/* src/components/GoogleAuthn.css */

/* Container for the entire OAuth login */
.google-oauth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9; /* Light background color */
    padding: 20px;
  }
  
  /* Centering the content box */
  .login-box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Heading and subheading styles */
  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 30px;
  }
  
  /* Google sign-in button styling */
  .google-login-button {
    padding: 12px 25px;
    background-color: #4285F4; /* Google blue */
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 300px;
  }
  
  .google-login-button:hover {
    background-color: #357ae8; /* Darker blue on hover */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .login-box {
      padding: 20px;
      width: 100%;
      max-width: 350px;
    }
  
    h2 {
      font-size: 1.8rem;
    }
  
    h3 {
      font-size: 1rem;
    }
  
    .google-login-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .login-box {
      padding: 15px;
      width: 100%;
      max-width: 300px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    h3 {
      font-size: 0.9rem;
    }
  
    .google-login-button {
      padding: 8px 18px;
      font-size: 13px;
    }
  }
  